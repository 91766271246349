const IconFacebook = ({
  fill = '#ffffff',
  className,
  dataTestid = 'icon-circle-facebook'
}) => (
  <div>
    <svg
      data-testid={dataTestid}
      className={className}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.5" cy="19.5" r="19" stroke={fill} />
      <path
        className={`${className}-path`}
        data-testid={`${dataTestid}-path`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7806 20.3614L23.163 17.4278H20.2237V15.5544C20.2237 14.7064 20.4635 14.1269 21.6955 14.1269L23.2662 14.1252V11.5017C22.9953 11.4669 22.0628 11.3856 20.9768 11.3856C18.71 11.3856 17.1594 12.7534 17.1594 15.265V17.4278H14.5957V20.3614H17.1594V27.8899H20.2237V20.3614H22.7807L22.7806 20.3614Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default IconFacebook;
